<template>
<div class="container-fluid">
      <ConfirmAlert ref="MyConfirmAlert" :title="AlertTitle" :message="AlertMessage" @Sure="YesIamSure($event)" :sureResult="sureResult" />
         <SuccessAlert ref="MySuccessAlert" :title="AlertTitle" :message="AlertMessage" :back="false"/>

            <div class="col-md-12">
                <div class="col-lg-12 col-md-12 ">
                <div class="row">
                    <div class="col-md-4">
                     <PerPage id="limit" title="كل صفحة"  Mykey="limit"  :MyValue="filter.limit" @Select="HandleSelect('limit',$event)"/>

                    </div>
                    <div class="col-md-4">
                    </div>
                    <div class="col-md-4">
                        <router-link v-if="manageService('can_add')" :to="{name: 'admin.dashboard.advertisement.create'}" class="btn btn-primary btn-md btn-add" > <i class="fas fa-pencil-alt"> </i>
                            اضافة اعلان</router-link>
                    </div>
                
                </div>
                </div>
            </div>

            <div class="col-md-12">
              <div class="card">
                <div class="card-header card-header-primary" style="text-align: center;">
                  <h4 class="card-title ">قائمة الاعلانات</h4>
                </div>
                <div class="card-body" style="text-align:center;">
                  <div class="table-responsive">
                    <table class="table">
                      <thead class=" text-primary">
                        <th>
                          #
                        </th>
                        <th>
                          صورة الاعلان بالعربي
                        </th>
                        <th>
                          صورة الاعلان بالانجليزي
                        </th>
                        <th>
                          حالة الاعلان
                        </th>
 
                        
                        <th v-if="manageService('can_edit') || manageService('can_delete')">
                          اجراءات
                        </th>

                      </thead>
                      <tbody>
                        <tr v-for="(Advertisement,index) in AdvertisementsList.data" :key="index">
                          <td>
                            {{index+1}}
                          </td>
                          <td>
                              <template v-if="Advertisement.path">
                                <img width="175" :src="Advertisement.path?' https://pode.ly'+Advertisement.path['ar']:''"/>
                              </template>
                              <template v-else>
                                  لايوجد
                              </template>
                            
                          </td>
                          <td>
                              <template v-if="Advertisement.path">
                                  <img width="175" :src="Advertisement.path?' https://pode.ly'+Advertisement.path['en']:''"/>
                              </template>
                              <template v-else>
                                  لايوجد
                              </template>
                              
                           </td>
                          <td>
                            {{Advertisement.status?'مفعل':'غير مفعل'}}
                          </td>

                          <td class="text-primary" v-if="manageService('can_edit') || manageService('can_delete')">

                            <router-link v-if="manageService('can_edit')" :to="{name: 'admin.dashboard.advertisement.edit', params: {id: Advertisement.id}}" class="btn btn-warning btn-sm" > 
                             تعديل</router-link>
                             <a href="" v-if="manageService('can_delete')" class="btn btn-danger btn-sm" @click.prevent="ShowConfirm(Advertisement)">
                             حذف</a> 
                          </td>
                        </tr>
                       </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
             <pagination v-if="AdvertisementsList.meta.last_page > 1" :pagination="AdvertisementsList" :offset="5" @paginate="onPaginationChage"></pagination> 
             </div>
</div>
</template>

<script>
import store from '../../../store/index'
import {mapState} from 'vuex';

import Pagination from '../../../components/Pagination.vue'
import PerPage from '../../../components/PerPage'
import ConfirmAlert from '../../../components/ConfirmAlert'
import SuccessAlert from '../../../components/SuccessAlert'
export default {
  data(){
   return{
   filter:{
        search:'',
        page:1,
        paginate:1,
        limit:'100',
    },  
     AlertTitle:'',
     AlertMessage:'',
     SelectedAdvertisement:null,
     sureResult:true,
   }
  },
  computed:{
    ...mapState({
        AdvertisementsList:state=>state.admin.advertisements.AdvertisementsList,
    }),
    manageService: (state) => (service) => {
            return store.getters['admin/canManageService'](service);
    },
    
   },
   methods:{
     HandleSelect(key,value){
         this.filter[key]=value;
         this.onPaginationChage(1);
     },
        onPaginationChage(page){
       this.filter.page=page;
         store.dispatch('admin/fetchAdvertisementsList',this.filter).then((response)=>{
         }).catch((error)=>{
         })
       },
        onSearch(){
          store.commit('admin/PleaseStartLoading');
                this.filter.page=1;
                store.dispatch('admin/fetchAdvertisementsList',this.filter).then(()=>{
                  store.commit('admin/PleaseStopLoading');
                }).catch((error)=>{
                  store.commit('admin/PleaseStopLoading');
                });  
        },  
        YesIamSure(value){
        if(value && this.sureResult){
            this.sureResult=false;
            this.onDelete();
        }
        },
        CancelAlert(){
        this.sureResult=false;
        },
        ShowConfirm(Advertisement){
                this.AlertTitle="معلومات",
                this.AlertMessage="هل انت متاكد من الحذف ؟";
                this.$refs.MyConfirmAlert.showModel();
                this.SelectedAdvertisement=Advertisement;
        },
         onDelete(){
           store.commit('admin/PleaseStartLoading');
          store.dispatch('admin/deleteAdvertise',this.SelectedAdvertisement.id).then((Response)=>{
            store.commit('admin/PleaseStopLoading');
            this.sure=false;
           this.$refs.MyConfirmAlert.hideModel();
            this.AlertTitle="معلومات",
            this.AlertMessage="تمت الحذف بنجاح";
            this.$refs.MySuccessAlert.showModel();
            store.dispatch('admin/fetchAdvertisementsList',this.filter).then(()=>{
             this.sureResult=true;
            }).catch(error=>{

            })
          }).catch((error)=>{
         store.commit('admin/PleaseStopLoading');
        });
        

    },
   },
      components:{
       Pagination,
       PerPage,
       ConfirmAlert,
       SuccessAlert
   },

  beforeRouteEnter(to,from,next){     
      store.commit('admin/PleaseStartLoading');
      store.dispatch('admin/fetchAdvertisementsList',{page:1,search:'',paginate:1,limit:100}).then(()=>{
         store.commit('admin/PleaseStopLoading');
          next();  
      }).catch((error)=>{
         store.commit('admin/PleaseStopLoading');
       next(false);
      });             

    },
}
</script>

<style>

</style>